import React, { useState } from "react";
import Sticky from "react-stickynode";

import { SanityCollectionConnection } from "@graphql-types";
import { Container } from "@util/standard";
import { maximumWidth } from "@util/helper";
import styled from "styled-components";
import { AnchorLink as GatsbyAnchorLink } from "gatsby-plugin-anchor-links";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  allSanityCollection: SanityCollectionConnection;
}

const StyledSticky = styled(Sticky)`
  .sticky-inner-wrapper {
    transition: background 0.3s ease;
  }
  &.active {
    .sticky-inner-wrapper {
      width: 100%;
      background: #e9e9e9;
      z-index: 21;
    }
  }
`;

const GatsbyAnchorLinkStyled = styled(GatsbyAnchorLink)`
  &:hover {
    text-decoration: underline;
  }
`;

const StickyNav = ({ allSanityCollection }: Props) => {
  const headerHeight = 76;
  const [stickyState, setStickyState] = useState(false);
  const maxWidth = maximumWidth();
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  const handleStateChange = (status: { status: number }) => {
    if (status.status === Sticky.STATUS_FIXED) {
      setStickyState(true);
      return;
    }
    setStickyState(false);
  };
  return (
    <>
      {!isTabletWidth && !isMobileWidth && (
        <StyledSticky
          enabled
          top={headerHeight}
          onStateChange={handleStateChange}
        >
          <Container
            margin="auto"
            width="100%"
            justifyContent="space-between"
            maxWidth={maxWidth}
          >
            {allSanityCollection.nodes.map(collection => {
              if (!collection) return;
              return (
                <Container
                  key={collection.id}
                  padding={stickyState ? "25px 0" : "33px 0"}
                >
                  <GatsbyAnchorLinkStyled
                    to={`/shop#${collection?.main?.slug?.current}`}
                    title={collection.main?.title as string}
                  />
                </Container>
              );
            })}
          </Container>
        </StyledSticky>
      )}
    </>
  );
};

export default StickyNav;
