import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import { Container } from "@util/standard";
import { pageWidthMargin } from "@util/helper";
import CategoryPreview from "@shared/categoryPreview";
import ImageTextBlockWithCarousel from "@shared/imageTextBlock";
import StickyNav from "@components/shopLandingPage/stickyNav";
import CollectionsPreview from "@components/shopLandingPage/collectionsPreview";
import Hero from "@shared/hero";
import { maximumWidth } from "@util/helper";
import { HeroCarousel } from "@global";
import SEO from "@shared/seo";
import Layout from "@shared/layout";
import { Query } from "@graphql-types";
import { useDataFromSanity } from "@util/hooks";
import { sanityClient } from "@lib/sanityClient";

interface Props {
  data: Query;
  pageContext: { iban: string; slug: string };
}

const ShopPage = ({ data, pageContext }: Props) => {
  const homeQuery = data.allSanityHomePage.nodes[0];
  const allSanityCollection = data.allSanityCollection;
  const pageData = data.allSanityShopLandingPage.nodes[0];
  // const featuredProducts =
  //   pageContext.iban == "nz"
  //     ? pageData.featuredProducts
  //     : pageData.featuredProductsAu;
  const margin = pageWidthMargin();

  const groqStr = `*[_type=="shopLandingPage" && region._ref == "563f237f-e8a2-493a-8f5c-fedca2374152"]{
    region,
      featuredProducts[]->{
        ...,
        "colour":*[_type=="category" && references(^._id)][0].categoryColour.value
      }
    }`;

  const [featuresProducts, setFeaturedProducts] = useState();

  const fetch = async () => {
    const g = await sanityClient.fetch(groqStr);
    if (g) {
      setFeaturedProducts(g[0]?.featuredProducts);
    }
    console.log(g);
  };

  useEffect(() => {
    if (!featuresProducts) {
      fetch();
    }
  }, []);

  useEffect(() => {
    console.log(featuresProducts);
  }, [featuresProducts]);

  if (!pageData) return null
  return (
    <>
      <SEO seoData={pageData.seo} />
      <Layout>
        <Container flexDirection="column">
          {/* Hero */}
          {pageData &&
          pageData.additionalHeros &&
          pageData.additionalHeros.length > 0 ? (
            <HeroCarousel
              hero={pageData?.hero}
              additionalHeros={pageData.additionalHeros}
            />
          ) : (
            <Hero sanityHero={pageData?.hero} />
          )}

          {/* Sticky Navigation */}
          <StickyNav allSanityCollection={allSanityCollection} />

          {/* List of Collections */}
          <Container
            flexDirection="column"
            margin="auto"
            width="100%"
            maxWidth={maximumWidth()}
          >
            {allSanityCollection.nodes.map((collection, index) => {
              if (!collection) return;
              return (
                <CollectionsPreview
                  key={collection.id}
                  collection={collection}
                  index={index}
                  length={allSanityCollection?.nodes?.length}
                />
              );
            })}
          </Container>

          {/* Featured Products */}
          {featuresProducts && featuresProducts.length > 0 && (
            <Container
              position="relative"
              width={`calc(100% - ${margin} - ${margin})`}
              margin="120px auto"
              tabletMargin="20px auto 60px"
            >
              <CategoryPreview
                products={featuresProducts}
                width={`calc(100% + ${margin})`}
                margin={`0 -${margin} 0 0`}
                tabletMargin={`0 -${margin} 0 0`}
                showTabletNav={true}
                title="Featured Products"
              />
            </Container>
          )}

          {/* Gifting */}
          {/* Same component as the one on the homepage */}
          {homeQuery.gifting && (
            <ImageTextBlockWithCarousel
              singleBlockData={homeQuery.gifting.giftingBlock}
              customHeight="650px"
              customMobileHeight="720px"
            />
          )}
        </Container>
      </Layout>
    </>
  );
};

export default ShopPage;

export const query = graphql`
  query ShopQuery($iban: String) {
    allSanityHomePage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        gifting {
          ...sanityGiftingLanding
        }
      }
    }
    allSanityCollection(
      sort: {fields: orderRank, order: ASC }
      filter: { main: { region: { iban: { eq: $iban } } } }
    ) {
      nodes {
        id
        _key
        ...sanityCollectionPreviewReduced
      }
    }
    allSanityShopLandingPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        additionalHeros {
          ...sanityHero
        }
        featuredProducts {
          ...sanityProductPreview
        }
        featuredProductsAu {
          ...sanityProductPreviewAu
        }
        seo {
          ...sanitySeo
        }
      }
    }
  }
`;
