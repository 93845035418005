import React from "react";
import styled from "styled-components";

import { SanityCollection } from "@graphql-types";
import { Container, H2, FlexGrid, P } from "@util/standard";
import { MOBILE_BREAKPOINT } from "@util/constants";
import { BlocksContent, Image, Button, PlaceholderImage } from "@global";
import TextButton from "@shared/textButton";
import { useCheckScreenWidth } from "@util/hooks";
interface Props {
  collection: SanityCollection;
  index: number;
  length: number;
}

const StyledBlocksContent = styled(Container)`
  margin-bottom: 30px;
  p {
    margin-top: 0;
    margin-bottom: 1em;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 21px;
  }
`;

const CollectionsPreview = ({ collection, index, length }: Props) => {
  const { isMobileWidth } = useCheckScreenWidth();

  const collectionMain = collection.main,
    richDescription = collectionMain?.hero?.descriptionRich?._rawBlocks,
    plainDescription = collectionMain?.hero?.description,
    title =
      collectionMain?.hero?.title ?? collectionMain?.title ?? "Collection";

  return (
    <Container flexDirection="column">
      <Container
        width="100%"
        margin={
          index === 0
            ? "25px 0 100px"
            : index === length - 1
            ? "100px 0 0"
            : "100px 0"
        }
        tabletMargin={index === 0 ? "40px 0 50px" : "50px 0"}
      >
        <FlexGrid
          gridMargin="100px"
          isCollectionPreview
          id={collectionMain?.slug?.current as string}
        >
          {/* Image */}
          <Container
            width="calc(100% - 348px)"
            tabletWidth="100%"
            tabletMargin="40px 0 0"
          >
            {collectionMain?.hero?.videoUrl?.image?.asset?.gatsbyImageData ? (
              <Image
                objectFit="cover"
                imageData={
                  collectionMain?.hero?.videoUrl?.image?.asset?.gatsbyImageData
                }
                width="100%"
                height="100%"
              />
            ) : (
              <PlaceholderImage width="100%" height="100%" altText={title} />
            )}
          </Container>

          {/* Content */}
          <Container width="348px" flexDirection="column">
            {/* Meta */}
            <H2 margin={isMobileWidth ? "0 0 8px" : "0 0 12px"}>{title}</H2>
            <StyledBlocksContent>
              {richDescription ? (
                <BlocksContent blocks={richDescription} />
              ) : (
                plainDescription && <P margin="0">{plainDescription}</P>
              )}
            </StyledBlocksContent>

            {/* Categories */}
            {collectionMain?.categories &&
              collectionMain.categories.map((category, i) => {
                if (!category) return;
                return (
                  <Container key={i} flexDirection="column">
                    <TextButton
                      stretch={1}
                      linkTo={`/${collectionMain.slug?.current}/${category.main?.slug?.current}`}
                      linkText={category.main?.title as string}
                    />
                    {i !==
                      (collectionMain?.categories?.length as number) - 1 && (
                      <Container
                        height="1px"
                        width="100%"
                        margin="14px 0"
                        opacity={0.4}
                        backgroundColor="merlin"
                      ></Container>
                    )}
                  </Container>
                );
              })}

            {/* CTA */}
            <Button
              width="100%"
              theme="transparent"
              text="View all"
              linkTo={`/${collectionMain?.slug?.current}`}
              margin={isMobileWidth ? "35px 0 0" : "45px 0 0"}
            />
          </Container>
          {/* EndContent */}
        </FlexGrid>
      </Container>
      {index !== length - 1 && (
        <Container
          height="1px"
          width="100%"
          backgroundColor="lightGrayBackground"
        ></Container>
      )}
    </Container>
  );
};

export default CollectionsPreview;
